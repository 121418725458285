import CardButton from "../../layout/card_button/CardButton";
import HistoryIcon from '@mui/icons-material/History';

type PeriodicReviewButtonProps = {
  title: string;
  description: string;
  loading: boolean;
  onClick: () => void;
};

const PeriodicReviewButton: React.FC<PeriodicReviewButtonProps> = ({
  title,
  description,
  loading,
  onClick,
}) => {
  return (
    <CardButton
      title={title}
      description={description}
      onClick={onClick}
      loading={loading}
      icon={<HistoryIcon color="action" fontSize="large" />}
    />
  );
};

export default PeriodicReviewButton;
