import ReviewWizardModal from "./ReviewWizardModal";
import { useState } from "react";
import useBreakpoints from "../../../hooks/useBreakpoints";

type ReviewWizardControllerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ReviewWizardController: React.FC<ReviewWizardControllerProps> = ({
  isOpen,
  onClose,
}) => {
  const { isXs } = useBreakpoints();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [reviewType, setReviewType] = useState<"thematic" | null>(null);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const onThematicClick = () => {
    setActiveStep(1);
    setReviewType("thematic");
    setCompleted({ ...completed, 0: true });
  };
  const onStepClick = (step: number) => {
    setActiveStep(step);
    setCompleted({ ...completed, [step]: false });
  };

  return (
    <ReviewWizardModal
      isOpen={isOpen}
      isXs={isXs}
      onClose={onClose}
      activeStep={activeStep}
      reviewType={reviewType}
      onStepClick={onStepClick}
      onThematicClick={onThematicClick}
      completed={completed}
    />
  );
};

export default ReviewWizardController;
