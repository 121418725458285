import Box from "@mui/material/Box";
import CardButton from "../../layout/card_button/CardButton";
import PeriodicReviewController from "../periodic_review_button/PeriodicReviewController";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import Typography from "@mui/material/Typography";

type ReviewWizardStepOneProps = {
  onThematicClick: () => void;
};

const ReviewWizardPicker: React.FC<ReviewWizardStepOneProps> = ({
  onThematicClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        sx={{
          marginTop: 2,
        }}
      >
        Select Review Type
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{
          marginBottom: 2,
        }}
      >
        Choose the type of review you would like to conduct.
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: (theme) => theme.palette.background.default,
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: "200px",
            height: "200px",
            margin: 1,
          }}
        >
          <PeriodicReviewController />
        </Box>
        <Box
          sx={{
            width: "200px",
            height: "200px",
            margin: 1,
          }}
        >
          <CardButton
            title="Thematic Review"
            description="Review a specific theme"
            onClick={onThematicClick}
            icon={<TipsAndUpdatesIcon color="action" fontSize="large" />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewWizardPicker;
