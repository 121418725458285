import Box from "@mui/material/Box";
import NewReviewButtonController from "../components/reviews/new_review_button/NewReviewButtonControler";
import PageContainer from "../components/layout/PageContainer";
import ReviewsListController from "../components/reviews/reviews_list/ReviewsListController";
import Typography from "@mui/material/Typography";

const Reviews = () => {
  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            height: "200px",
            width: "200px",
            padding: 1,
          }}
        >
          <NewReviewButtonController />
        </Box>
      </Box>
      <Typography variant="h6" sx={{ marginY: 2 }}>
        Completed Reviews
      </Typography>
      <ReviewsListController />
    </PageContainer>
  );
};

export default Reviews;
