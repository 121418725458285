import Box from "@mui/material/Box";
import DefaultModal from "../../layout/DefaultModal";
import ReviewWizardPicker from "./ReviewWizardPicker";
import ReviewWizardThemeConfigurationController from "../review_wizard_theme_configuration/ReviewWizardThemeConfigurationController";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepButton from "@mui/material/StepButton";

type ReviewWizardModalProps = {
  isOpen: boolean;
  isXs: boolean;
  onClose: () => void;
  activeStep: number;
  reviewType: "thematic" | null;
  onStepClick: (step: number) => void;
  onThematicClick: () => void;
  completed: {
    [k: number]: boolean;
  };
};

const ReviewWizardModal: React.FC<ReviewWizardModalProps> = ({
  isOpen,
  isXs,
  onClose,
  activeStep,
  reviewType,
  onStepClick,
  onThematicClick,
  completed,
}) => {
  const steps = ["Select review type", "Configure review"];

  return (
    <DefaultModal isOpen={isOpen} onClose={onClose} overflowY="hidden">
      {activeStep === 0 && (
        <ReviewWizardPicker onThematicClick={onThematicClick} />
      )}
      {activeStep === 1 && reviewType === "thematic" && (
        <ReviewWizardThemeConfigurationController />
      )}
      <Box
        sx={{
          width: "100%",
          height: "3.5rem",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Stepper
          nonLinear
          activeStep={activeStep}
          sx={{
            width: isXs ? "80%" : "50%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                onClick={() => onStepClick(index)}
                disabled={!completed[index] || index > activeStep}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </DefaultModal>
  );
};

export default ReviewWizardModal;
