import { array, object, string } from "yup";
import { ErrorMessage, Page } from "@beyondrealityapp/core/shared/constants";
import { useFormik } from "formik";
import {
  useCreateReviewMutation,
  useGetCountGoalsQuery,
} from "../../../api/review";
import ReviewWizardThemeConfigurationView from "./ReviewWizardThemeConfigurationView";
import { ReviewWizardFormType } from "@beyondrealityapp/core/review/types";
import { useNavigate } from "react-router";

const ReviewWizardThemeConfigurationController = () => {
  const navigate = useNavigate();
  const [createReview] = useCreateReviewMutation();
  const onSubmit = () => {
    formik.setSubmitting(true);
    createReview({
      type: "thematic",
      themes: formik.values.themes,
      states: formik.values.states,
    })
      .then((response) => {
        if ("data" in response && response.data) {
          navigate(`/${Page.REVIEWS}/${response.data.id}/0`);
        } else if ("error" in response) {
          formik.setErrors({ themes: response.error as string });
        } else {
          formik.setErrors({ themes: ErrorMessage.UNKNOW_ERROR });
        }
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const initialValues: ReviewWizardFormType = {
    themes: [],
    states: ["In progress"],
    type: "thematic",
  };

  const validationSchema = object({
    themes: array(),
    states: array(),
    type: string().oneOf(["thematic", "periodic"]),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    data: goalsPreviewCount,
    isLoading: isLoadingGoalsPreviewCount,
    isError: isGoalsPreviewCountError,
    error: goalsPreviewCountError,
  } = useGetCountGoalsQuery({
    themes: formik.values.themes,
    states: formik.values.states,
    type: "thematic",
  });
  return (
    <ReviewWizardThemeConfigurationView
      formik={formik}
      goalsPreviewCount={goalsPreviewCount}
      isLoadingGoalsPreviewCount={isLoadingGoalsPreviewCount}
      isGoalsPreviewCountError={isGoalsPreviewCountError}
      goalsPreviewCountError={goalsPreviewCountError as Error}
    />
  );
};

export default ReviewWizardThemeConfigurationController;
