import AddGoalCard from "../add_goal_card/AddGoalCard";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ExpandMore from "../../layout/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlagIcon from "@mui/icons-material/Flag";
import { GoalClass } from "@beyondrealityapp/core/goal/classes";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { TransitionGroup } from "react-transition-group";
import Typography from "@mui/material/Typography";
import useBreakpoints from "../../../hooks/useBreakpoints";

type GoalCardViewProps = {
  onClickHandler: () => void;
  onExpandClickHandler: () => void;
  onSubgoalClickHandler: (event: React.MouseEvent, id: string) => void;
  onAddSubGoalClickHandler: (event: React.MouseEvent) => void;
  onSubGoalCancelClickHandler: () => void;
  showAddSubGoalCard: boolean;
  expanded: boolean;
  highlightColor: string;
  image: string;
  content: string;
  description: string;
  progress: string;
  dates: string;
  themes: string[];
  children: {
    [id: string]: {
      name: string;
    };
  };
  parent: string;
  updatedAt: string;
  compact?: boolean;
};

const GoalCardView: React.FC<GoalCardViewProps> = ({
  onClickHandler,
  onExpandClickHandler,
  onSubgoalClickHandler,
  onAddSubGoalClickHandler,
  onSubGoalCancelClickHandler,
  showAddSubGoalCard,
  expanded,
  highlightColor,
  image,
  content,
  description,
  progress,
  dates,
  themes,
  children,
  parent,
  updatedAt,
  compact,
}) => {
  const { isXs } = useBreakpoints();
  return (
    <>
      <Card
        onClick={onClickHandler}
        sx={{
          width: "100%",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            minHeight: "80px",
          }}
        >
          <Box
            sx={{
              width: "4px",
              backgroundColor: highlightColor,
            }}
          />
          {!compact && image ? (
            <CardMedia
              sx={{
                width: isXs ? "25%" : "20%",
              }}
              image={image}
            />
          ) : null}
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Tooltip title={content} placement="top">
                <div>
                  <CardHeader
                    titleTypographyProps={{
                      variant: "body2",
                      fontWeight: 700,
                    }}
                    title={content}
                    sx={{
                      height: "100%",
                      "& .MuiCardHeader-title": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        wordBreak: "break-word",
                      },
                    }}
                    subheader={!compact ? progress : null}
                    subheaderTypographyProps={{
                      variant: "body2",
                      color: "text.secondary",
                    }}
                  />
                </div>
              </Tooltip>
              <ExpandMore
                expand={expanded}
                onClick={(event) => {
                  event.stopPropagation();
                  onExpandClickHandler();
                }}
                aria-expanded={expanded}
                aria-label="show more"
                sx={{
                  marginRight: 1,
                }}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container direction="column" rowSpacing={1}>
              <Grid item>
                {description ? (
                  <Typography variant="body2" color="text.secondary">
                    {description.split("\n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                ) : null}
              </Grid>
              {dates ? (
                <Grid item>
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.secondary"
                  >
                    <CalendarMonth color="inherit" fontSize="small" />
                    <Typography variant="body2" marginLeft={1}>
                      {dates}
                    </Typography>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
          <Grid item sx={{
            display: "flex",
            marginBottom: 1,
            marginLeft: 2,
          }}>

            {themes.length !== 0 ? (
              <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                {themes.map((theme) => (
                  <Grid item key={theme}>
                    <Chip label={theme} size="small" color="primary" />
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {updatedAt ? (
              <Typography variant="caption">
                Last updated {updatedAt}
              </Typography>
            ) : null}
          </Grid>
          <Box>
            <Divider />
            <Box
              onClick={(event) => event.stopPropagation()}
              sx={{
                "&:hover": {
                  cursor: "initial",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  marginLeft: 2,
                  marginTop: 2,
                }}
              >
                <FlagIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontWeight: 600,
                    marginLeft: 1,
                  }}
                >
                  Subgoals:
                </Typography>
              </Box>
              <List
                sx={{
                  marginLeft: 1,
                }}
                dense
              >
                <TransitionGroup>
                  {Object.entries(children).map(([id, child]) => (
                    <Collapse key={id} unmountOnExit>
                      <ListItem
                        key={id}
                        onClick={(event) => onSubgoalClickHandler(event, id)}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <ListItemText primary={child.name} />
                        <IconButton
                          size="small"
                          onClick={(event) => onSubgoalClickHandler(event, id)}
                        >
                          <LaunchIcon fontSize="small" />
                        </IconButton>
                      </ListItem>
                    </Collapse>
                  ))}
                  {showAddSubGoalCard && (
                    <Collapse key={"add-goal-card"} unmountOnExit>
                      <ListItem key={"add-goal-card"}>
                        <AddGoalCard
                          variant="outlined"
                          onCancleClick={onSubGoalCancelClickHandler}
                          goal={
                            new GoalClass({
                              parent: parent,
                            })
                          }
                        />
                      </ListItem>
                    </Collapse>
                  )}
                  {!showAddSubGoalCard && (
                    <Collapse>
                      <ListItem>
                        <Button
                          variant="text"
                          startIcon={<AddIcon />}
                          size="small"
                          onClick={onAddSubGoalClickHandler}
                        >
                          Add Subgoal
                        </Button>
                      </ListItem>
                    </Collapse>
                  )}
                </TransitionGroup>
              </List>
            </Box>
          </Box>
        </Collapse>
      </Card>
    </>
  );
};

export default GoalCardView;
