import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import { GoalStatusUtility } from "@beyondrealityapp/core/goal/classes";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { ReviewWizardFormType } from "@beyondrealityapp/core/review/types";
import Select from "@mui/material/Select";
import SubmitButton from "../../layout/SubmitButton";
import Typography from "@mui/material/Typography";
import ThemeMultiSelectController from "../../themes/themes_multi_select/ThemeMultiSelectController";

type ReviewWizardThemeConfigurationViewProps = {
  formik: FormikState<ReviewWizardFormType> &
    FormikHelpers<ReviewWizardFormType> &
    FormikHandlers;
  goalsPreviewCount: number | undefined;
  isLoadingGoalsPreviewCount: boolean;
  isGoalsPreviewCountError: boolean;
  goalsPreviewCountError: Error;
};

const ReviewWizardThemeConfigurationView: React.FC<
  ReviewWizardThemeConfigurationViewProps
> = ({
  formik,
  goalsPreviewCount,
  isLoadingGoalsPreviewCount,
  isGoalsPreviewCountError,
  goalsPreviewCountError,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        sx={{
          marginTop: 2,
        }}
      >
        Select themes
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{
          marginBottom: 2,
        }}
      >
        Choose the themes you would like to review.
      </Typography>
      <Box sx={{ width: "80%" }} component="form">
        <ThemeMultiSelectController
          onChange={formik.handleChange}
          value={formik.values.themes}
          error={formik.touched.themes && Boolean(formik.errors.themes)}
        />
        <FormControl margin="dense" fullWidth>
          <InputLabel id="states" variant="standard">
            States
          </InputLabel>
          <Select
            id="states"
            name="states"
            label="States"
            variant="standard"
            placeholder="States"
            value={formik.values.states}
            onChange={formik.handleChange}
            renderValue={(selected) => selected.join(", ")}
            multiple
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            fullWidth
          >
            {GoalStatusUtility.listStatuses().map((state) => (
              <MenuItem key={state} value={state}>
                <ListItemText primary={state} />
                {formik.values.states.includes(state) && (
                  <CheckBoxIcon color="primary" fontSize="small" />
                )}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText variant="standard">
            {formik.touched.states && formik.errors.states}
            {!formik.errors.states && "Leave empty to include all states."}
          </FormHelperText>
        </FormControl>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          sx={{
            marginBottom: 2,
          }}
        >
          {!isGoalsPreviewCountError &&
            isLoadingGoalsPreviewCount &&
            "Loading goals count..."}
          {!isGoalsPreviewCountError &&
            !isLoadingGoalsPreviewCount &&
            goalsPreviewCount !== 0 &&
            `This review will contain ${goalsPreviewCount} goals.`}
          {!isGoalsPreviewCountError &&
            goalsPreviewCount === 0 &&
            "These filters do not match any goals."}
          {isGoalsPreviewCountError && goalsPreviewCountError.message}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <SubmitButton
            onClick={formik.handleSubmit}
            submitting={formik.isSubmitting}
            disabled={goalsPreviewCount === 0}
            text="Create review"
            submittingText="Creating review..."
          />
        </Box>
        <Collapse in={formik.touched.themes && Boolean(formik.errors.themes)}>
          <Alert severity="error">{formik.errors.themes}</Alert>
        </Collapse>
      </Box>
    </Box>
  );
};

export default ReviewWizardThemeConfigurationView;
