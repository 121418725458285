import CardButton from "../../layout/card_button/CardButton";
import ThemeModalController from "../theme_modal/ThemeModalController";
import { useState } from "react";

const AddThemeCardButton: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setModalOpen((prevState) => !prevState);
  };
  return (
    <>
      <CardButton title="Add Theme" onClick={toggleModal} />
      <ThemeModalController isOpen={modalOpen} onClose={toggleModal} />
    </>
  );
};

export default AddThemeCardButton;
