import {
  ErrorMessage,
  Page,
  Severity,
} from "@beyondrealityapp/core/shared/constants";
import PeriodicReviewButton from "./PeriodicReviewButton";
import { showSnackbar } from "../../../features/snackbar";
import { useCreateReviewMutation } from "../../../api/review";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";

const PeriodicReviewController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createReview] = useCreateReviewMutation();
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    createReview(undefined)
      .then((response) => {
        if ("data" in response && response.data) {
          navigate(`/${Page.REVIEWS}/${response.data.id}/0`);
        } else if ("error" in response) {
          dispatch(
            showSnackbar({ message: response.error, severity: Severity.ERROR })
          );
        } else {
          dispatch(
            showSnackbar({
              message: ErrorMessage.UNKNOW_ERROR,
              severity: Severity.ERROR,
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <PeriodicReviewButton
      title="Periodic Review"
      description="Review all active goals"
      onClick={onClick}
      loading={loading}
    />
  );
};

export default PeriodicReviewController;
