import Box from "@mui/material/Box";
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  listsPlugin,
  ListsToggle,
  headingsPlugin,
  MDXEditor,
  markdownShortcutPlugin,
  quotePlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import { useTheme } from "@mui/material/styles";

type MarkdownEditorProps = {
  markdown?: string;
  onBlur?: (event: FocusEvent) => void;
  onChange: (markdown: string) => void;
  onError?: (payload: { error: string; source: string }) => void;
  disabled?: boolean;
  placeholder?: string;
};

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  markdown = "#",
  onBlur,
  onChange,
  onError,
  disabled,
  placeholder,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        "& .mdxeditor": {
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
          fontFamily: theme.typography.fontFamily,
          "--baseBgHover": theme.palette.action.hover,
          "--baseBgActive": theme.palette.action.selected,
          "--baseBorder": theme.palette.divider,
          "& blockquote": {
            borderLeft: `4px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(1),
          },
        },
        "& .mdxeditor-toolbar": {
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[1],
        },
      }}
    >
      <MDXEditor
        markdown={markdown}
        contentEditableClassName="mdxeditor"
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          quotePlugin(),
          markdownShortcutPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <BlockTypeSelect />
                <BoldItalicUnderlineToggles />
                <ListsToggle />
              </>
            ),
          }),
        ]}
        onBlur={onBlur}
        onChange={onChange}
        onError={onError}
        readOnly={disabled}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default MarkdownEditor;
