import InfoSidebarView from "./InfoSidebarView";
import { useEffect, useState } from "react";

type InfoSideBarControllerProps = {
  open: boolean;
  toggleDrawer: () => void;
};

const InfoSideBarController: React.FC<InfoSideBarControllerProps> = ({
  open,
  toggleDrawer,
}) => {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    setMarkdownContent("# BeyondReality\nBeyondReality helps you..\n1. **Know yourself**: Get insight in who you are;\n2. **Define yourself**: Define who you would like to become.\n3. **Become yourself**: Help you become yourself.\nOpen the menu on the left to discover the different modules.\nClicking the question mark icon in the top right corner will give you additional information about this module.");
  }, []);

  return (
    <InfoSidebarView
      open={open}
      markdownContent={markdownContent}
      toggleDrawer={toggleDrawer}
    />
  );
};

export default InfoSideBarController;
