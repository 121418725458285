import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../config/firebase";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createReviewProps,
  ReviewWizardFormType,
} from "@beyondrealityapp/core/review/types";
import { ErrorMessage } from "@beyondrealityapp/core/shared/constants";
import { getCountFromServer } from "firebase/firestore";
import { GoalField } from "@beyondrealityapp/core/goal/constants";
import { GoalType } from "@beyondrealityapp/core/goal/types";
import {
  Model,
  Operator,
  Order,
} from "@beyondrealityapp/core/shared/constants";
import { ReviewClass } from "@beyondrealityapp/core/review/classes";
import { ReviewField } from "@beyondrealityapp/core/review/constants";
import {
  ReviewFirestoreType,
  ReviewFormType,
  ReviewListFilterType,
} from "@beyondrealityapp/core/review/types";

export const reviewApi = createApi({
  reducerPath: "reviewApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Review"],
  endpoints: (builder) => ({
    getCountGoals: builder.query({
      queryFn: async (filter: ReviewWizardFormType) => {
        try {
          const count = await _getCountGoalsCount(filter.themes, filter.states);
          return { data: count };
        } catch (error) {
          return { error: error as Error };
        }
      },
    }),
    fetchReviews: builder.query({
      queryFn: async (filter: ReviewListFilterType) => {
        try {
          const reviews = await _fetchReviews(filter);
          return { data: reviews };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["Review"],
    }),
    fetchReview: builder.query({
      queryFn: async (reviewId: string) => {
        try {
          const review = await _fetchReview(reviewId);
          return { data: review };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["Review"],
    }),
    createReview: builder.mutation({
      queryFn: async (
        props: createReviewProps = { type: "periodic", states: ["In progress"] }
      ) => {
        try {
          const review = await _createReview(props);
          return { data: review };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Review"],
    }),
    updateReview: builder.mutation({
      queryFn: async (reviewForm: Partial<ReviewFormType>) => {
        try {
          const review = await _updateReview(reviewForm);
          return { data: review };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Review"],
    }),
    deleteReview: builder.mutation({
      queryFn: async (reviewId: string) => {
        try {
          await _deleteReview(reviewId);
          return { data: reviewId };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Review"],
    }),
  }),
});

export const {
  useGetCountGoalsQuery,
  useFetchReviewsQuery,
  useFetchReviewQuery,
  useCreateReviewMutation,
  useUpdateReviewMutation,
  useDeleteReviewMutation,
} = reviewApi;

const _fetchReviews = (filter: ReviewListFilterType) => {
  const q = query(
    collection(db, Model.REVIEWS),
    where(ReviewField.UID, Operator.EQUAL_TO, auth.currentUser?.uid),
    orderBy(ReviewField.CREATED_AT, Order.DESCENDING),
    limit(filter.page * 10)
  );
  return new Promise<ReviewClass[]>((resolve, reject) => {
    getDocs(q)
      .then((querySnapshot) => {
        const reviews: ReviewClass[] = [];
        querySnapshot.forEach((doc) => {
          const review = ReviewClass.fromFirestore(
            doc.data() as ReviewFirestoreType
          );
          reviews.push(review);
        });
        resolve(reviews);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _fetchReview = (id: string) => {
  return new Promise<ReviewClass>((resolve, reject) => {
    getDoc(doc(db, Model.REVIEWS, id)).then((doc) => {
      if (doc.exists()) {
        const review = ReviewClass.fromFirestore(
          doc.data() as ReviewFirestoreType
        );
        resolve(review);
      } else {
        reject(ErrorMessage.NOT_FOUND);
      }
    });
  });
};

const _createReview = async (
  props: createReviewProps = { type: "periodic", states: ["In progress"] }
) => {
  let goalsFieldFilters = [];

  if (props.type === "thematic" && props.themes.length > 0) {
    const themesFilter = where(
      GoalField.THEMES,
      Operator.ARRAY_CONTAINS_ANY,
      props.themes
    );
    goalsFieldFilters.push(themesFilter);
  }
  if (props.states.length > 0) {
    const statesFilter = where(GoalField.STATUS, Operator.IN, props.states);
    goalsFieldFilters.push(statesFilter);
  }

  const q = query(
    collection(db, Model.GOALS),
    where(GoalField.UID, Operator.EQUAL_TO, auth.currentUser?.uid),
    ...goalsFieldFilters
  );

  const goalsQuerySnapshot = await getDocs(q);

  return new Promise<ReviewClass>((resolve, reject) => {
    _generateReview(db, goalsQuerySnapshot, props.type)
      .then((review) => {
        resolve(review);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _generateReview = async (
  db: Firestore,
  goalsQuerySnapshot: QuerySnapshot,
  type: "periodic" | "thematic"
) => {
  return new Promise<ReviewClass>((resolve, reject) => {
    if (goalsQuerySnapshot.empty) {
      reject(ErrorMessage.NOT_FOUND);
    }
    const review = new ReviewClass({
      type,
      reviewedGoals: [],
    });
    review.init(auth);

    goalsQuerySnapshot.forEach((doc) => {
      review.appendReviewedGoal(doc.id, doc.data() as GoalType);
    });

    setDoc(doc(db, Model.REVIEWS, review.id), review.toFirestore()).then(() => {
      resolve(review);
    });
  });
};

const _updateReview = async (review: Partial<ReviewFormType>) => {
  return new Promise<void>((resolve, reject) => {
    const updatedReview = ReviewClass.fromForm(review);

    updatedReview.setUpdatedTimestamp();

    runTransaction(db, async (transaction) => {
      updatedReview.reviewedGoals.forEach((reviewedGoal) => {
        transaction.update(doc(db, Model.GOALS, reviewedGoal.id), {
          current: reviewedGoal.newCurrent,
        });
      });
      transaction.update(doc(db, Model.REVIEWS, updatedReview.id), {
        reviewedGoals: updatedReview.reviewedGoals,
        "metaData.updatedAt": updatedReview.metaData?.updatedAt,
      });
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _deleteReview = (id: string) => {
  return new Promise<void>((resolve, reject) => {
    deleteDoc(doc(db, Model.REVIEWS, id))
      .then(() => {
        resolve();
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

const _getCountGoalsCount = async (
  themes: string[] = [],
  states: string[] = []
) => {
  return new Promise<number>((resolve, reject) => {
    let goalsFieldFilters = [];

    if (themes.length > 0) {
      const themesFilter = where(
        GoalField.THEMES,
        Operator.ARRAY_CONTAINS_ANY,
        themes
      );
      goalsFieldFilters.push(themesFilter);
    }
    if (states.length > 0) {
      const statesFilter = where(GoalField.STATUS, Operator.IN, states);
      goalsFieldFilters.push(statesFilter);
    }

    const q = query(
      collection(db, Model.GOALS),
      where(GoalField.UID, Operator.EQUAL_TO, auth.currentUser?.uid),
      ...goalsFieldFilters
    );
    getCountFromServer(q)
      .then((snapshot) => {
        resolve(snapshot.data().count);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
