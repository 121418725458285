import ReviewWizardController from "../review_wizard/ReviewWizardController";
import NewReviewButton from "./NewReviewButton";
import { useState } from "react";

const NewReviewButtonController = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClick = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <NewReviewButton
        title="New Review"
        description="Start a new review"
        onClick={onClick}
      />
      {isOpen && <ReviewWizardController isOpen={isOpen} onClose={onClose} />}
    </>
  );
};

export default NewReviewButtonController;
