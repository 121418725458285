import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

type SubmitButtonProps = {
  submitting: boolean;
  text?: string;
  submittingText?: string;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  submitting,
  text,
  submittingText,
  onClick,
  size,
  disabled,
}) => {
  const buttonText = text ? text : "Save";
  const buttonSubmittingText = submittingText ? submittingText : "Saving";

  return (
    <Button
      variant="contained"
      type="submit"
      disabled={disabled || submitting}
      onClick={onClick ? onClick : () => {}}
      sx={{
        marginRight: 1,
      }}
      endIcon={
        submitting ? (
          <CircularProgress sx={{ color: "white" }} size={20} thickness={5} />
        ) : null
      }
      size={size ? size : "medium"}
    >
      {submitting ? buttonSubmittingText : buttonText}
    </Button>
  );
};

export default SubmitButton;
